import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { NotificationContext } from "src/context/NotificationContext";
import MiniSidebarItem from "./components/MiniSidebarItem";

export default function MiniSidebar() {
  const { notifications } = useContext(NotificationContext);

  // Sidebar Items
  const _SIDEBAR = [
    { title: "T.DASHBOARD", path: "/dashboard", icon: "home" },
    { title: "T.COMPANIES", path: "/companies", icon: "building" },
  ];

  return (
    <div className="hidden md:flex sticky top-2 p-2 border rounded-md flex-col flex-shrink-0 w-64 bg-white">
      <div className="h-16 rounded-md mb-2 flex items-center justify-center bg-blue-500">
        <span className="text-white font-bold uppercase">RedChat Master Panel</span>
      </div>
      {_SIDEBAR.map(({ title, icon, active, path, notification, onClick }, _index) => (
        <Fragment key={_index}>
          {path ? (
            <Link to={path}>
              <MiniSidebarItem title={title} icon={icon} active={active} notification={notification} />
            </Link>
          ) : (
            <MiniSidebarItem title={title} icon={icon} active={active} notification={notification} onClick={onClick} />
          )}
        </Fragment>
      ))}
    </div>
  );
}

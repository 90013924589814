import { ArrowLeft, Bell, Building, ChevronLeft, Home, User } from "lucide-react";

const _FAHERO = {
  home: Home,
  building: Building,
  "arrow-left": ArrowLeft,
  "chevron-left": ChevronLeft,
  user: User,
  bell: Bell,
};

export default function AppIcon({ code = "", className = "", ...props }) {
  if (!code || !_FAHERO[code]) return <></>;
  const Icon = _FAHERO[code];
  return <Icon className={className} {...props} />;
}

import AppButton from "@components/AppButton";
import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { AppInput, RowInput } from "../../components/Form";

export default function LoginPage() {
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);

  // Get Public Assets
  const GPM = useMutation(() => axios.get(`${ENDPOINTS.public}/${window.location.href.split("/")[2]}`));

  useEffect(() => {
    GPM.mutate();
  }, []);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(ENDPOINTS.auth.login, values), {
    onSuccess: (response) => {
      if (response.token) login(response.token);
    },
    onError: (error) => {
      setError(error);
    },
  });

  return (
    <div className="flex h-screen flex-col justify-center bg-gray-200 px-8 items-center">
      <div className="flex flex-col border rounded-md shadow-md p-8 bg-white w-full lg:w-96">
        <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex flex-col">
                <Field required type="text" name="email" component={RowInput} FormElement={AppInput} placeholder="Email" className="mb-2" />
                <Field component={RowInput} FormElement={AppInput} required name="password" type="password" placeholder="Password" className="mb-6" />
                <AppButton mode="primary" title="T.SIGN_IN" loading={LDM.isLoading} />
                {error && <div className="bg-red-200 mt-3  h-[50px]">{error?.message}</div>}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

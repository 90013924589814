import AppIcon from "@components/AppIcon";
import clx from "classnames";
import { memo } from "react";
import { useIntl } from "react-intl";
const MiniSidebarItem = ({ icon, title, active, notification, onClick }) => {
  const intl = useIntl();
  return (
    <div
      onClick={onClick}
      className={clx("flex h-16 w-full items-center px-4 space-x-4 cursor-pointer relative font-medium rounded-md", {
        "bg-gray-100 text-gray-700": active,
        "bg-white hover:bg-gray-50 text-gray-500 ": !active,
      })}
    >
      <AppIcon code={icon} className="w-6 h-6 flex-shrink-0" aria-hidden="true" />
      <span className="truncate">{intl.formatMessage({ id: title })}</span>
      {notification && <div className="w-2 h-2 flex-shrink-0 rounded-full bg-blue-500" />}
    </div>
  );
};
export default memo(MiniSidebarItem);

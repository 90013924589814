// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Notification Base URL
export const NOTIFICATION_BASE_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;

// App Name
export const APP_NAME = process.env.REACT_APP_APP_NAME;

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

// Desktop Notifications
export const DESKTOP_NOTIFICATION_CODES = ["NEW.MESSAGE"];

export const HIDDEN_TOAST_ERROR_CODES = [401];

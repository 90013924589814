import clx from "classnames";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AppIcon from "./AppIcon";
import Spinner from "./Spinner";

const _MODES = {
  primary: "py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold",
  "light-primary": "py-2 px-4 opacity-70 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white",
  success: "py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white",
  "light-success": "py-2 px-4 bg-white text-green-600 border border-green-600 hover:text-gray-100 hover:bg-green-600 focus:ring-green-500 text-white",
  warning: "py-2 px-4 bg-amber-600 hover:bg-amber-700 focus:ring-amber-500 text-white",
  "light-warning": "py-2 px-4 opacity-70 bg-amber-600 hover:bg-amber-700 focus:ring-amber-500 text-white",
  danger: "py-2 px-4 bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white",
  "light-danger": "py-2 px-4 bg-white hover:bg-red-500 border border-red-300 focus:ring-red-500 text-red-700 hover:text-gray-100",
  light: "py-2 px-4 border bg-white hover:bg-gray-100 focus:ring-gray-500 text-gray-500",
  icon: "p-2 rounded-md focus:ring-0 text-gray-500 hover:text-gray-700",
};

const _SIZES = {
  sm: "h-8",
  md: "h-12",
};

export default function AppButton({
  loading,
  disabled = false,
  title,
  tooltip = "",
  mode = "light",
  icon,
  iconClassname,
  size = "md",
  href,
  className = "",
  onClick = () => {},
}) {
  const intl = useIntl();

  const navigate = useNavigate();

  const _disabled = disabled || loading;

  return (
    <button
      disabled={disabled || loading}
      onClick={() => {
        if (href) return navigate(href);
        else return onClick();
      }}
      title={tooltip}
      className={`flex items-center justify-center rounded-md ${_MODES[mode]} ${_SIZES[size]} ${_disabled ? "opacity-75" : "cursor-pointer"} ${className}`}
    >
      <>
        {icon ? (
          loading ? (
            <Spinner className="w-6 h-6" />
          ) : (
            <AppIcon code={icon} className={clx("w-5 h-5", iconClassname)} />
          )
        ) : loading ? (
          <Spinner className="w-6 h-6" />
        ) : (
          <></>
        )}
        {title ? <span className={clx("leading-none", { "ml-2": icon || loading })}>{intl.formatMessage({ id: title ?? "-" })}</span> : <></>}
      </>
    </button>
  );
}

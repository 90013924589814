import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RouteContainer from "../components/core/RouteContainer";
import MasterLayout from "../components/layouts/MasterLayout";

const ErrorPage = lazy(() => import("@modules/error/ErrorPage"));
const DashboardPage = lazy(() => import("@modules/dashboard/DashboardPage"));
const CompanyPage = lazy(() => import("@modules/companies/CompanyPage"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/companies" /> },
  { path: "/dashboard/*", element: <DashboardPage /> },
  { path: "/error/*", element: <ErrorPage code={404} /> },
  { path: "/companies/*", element: <CompanyPage /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}

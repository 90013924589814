import { Outlet } from "react-router-dom";
import { NotificationProvider } from "src/context/NotificationContext";
import MiniSidebar from "./mini-sidebar/MiniSidebar";

export default function MasterLayout() {
  return (
    <NotificationProvider>
      <div className="flex space-x-2 p-2">
        <div className="relative">
          <MiniSidebar />
        </div>
        <div className="flex-grow min-h-screen">
          <Outlet />
        </div>
      </div>
    </NotificationProvider>
  );
}
